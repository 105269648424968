<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="view">
      <div class="title">
        账号总览<span @click="getRechargeAgentRecord"><i class="el-icon-tickets"></i>查看充值记录</span>
      </div>
      <div class="flex totle">
        <div class="list">
          <div
            class="top"
            style="background: linear-gradient(0deg, #01e9bd 0%, #007cde 100%)"
          >
            <div class="num">
              <!-- <span>{{ minute(tableData.usedVideoTime) }}</span
              >/{{ minute(tableData.totalVideoTime) }} -->
              {{ minute(tableData.totalVideoTime - tableData.usedVideoTime) }}
            </div>
            <span>分钟</span>
          </div>
          <div class="bottom">短视频剩余时长</div>
        </div>
        <div class="list">
          <div
            class="top"
            style="background: linear-gradient(0deg, #5746ed 0%, #0ab9fe 100%)"
          >
            <div class="num">
              <!-- <span>{{ minute(tableData.usedCreditTime) }}</span
              >/{{ minute(tableData.totalCreditTime) }} -->
              {{ minute(tableData.totalCreditTime - tableData.usedCreditTime) }}
            </div>
            分钟
          </div>
          <div class="bottom">无人直播剩余时长</div>
        </div>
        <div class="list">
          <div
            class="top"
            style="background: linear-gradient(0deg, #40b9fe 0%, #a443ff 100%)"
          >
            <div class="num">
              <!-- <span>{{ minute(tableData.usedLiveRPTime) }}</span
              >/{{ minute(tableData.totalLiveRPTime) }} -->
              {{ minute(tableData.totalLiveRPTime - tableData.usedLiveRPTime) }}
            </div>
            分钟
          </div>
          <div class="bottom">真人驱动直播剩余时长</div>
        </div>
      </div>
    </div>
    <div class="view" style="margin-top: 30px">
      <div class="flex">
        <div class="title">客户详情</div>
        <div class="flex">
          <el-date-picker
            class="waicengPhone"
            v-model="searchEle.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-input
            class="waicengPhone"
            v-model="searchEle.terName"
            placeholder="请输入客户名称"
            clearable
            maxlength="11"
          ></el-input>
          <el-input
            class="waicengPhone"
            v-model="searchEle.terPhone"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-button
            @click="(currentPage = 1), getfactorysList()"
            type="primary"
          >
            查询
          </el-button>
        </div>
      </div>
      <el-table :data="tableData.ipage.records" stripe class="HNMR">
        <el-table-column prop="terName" label="客户名称"> </el-table-column>
        <el-table-column prop="terPhone" label="手机号"> </el-table-column>
        <el-table-column prop="liveRPTime" label="创建时间">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="openRecord(scope.row)" type="text"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-row>
    </div>
    <el-dialog
      class="commonDialog"
      :title="recordPopup.type==1?'查看详情':'充值记录'"
      :visible.sync="recordPopup.show"
      width="900px"
    >
      <recordPopup
        ref="record"
        :typeShow="recordPopup.type"
        :detailData="recordPopup.res"
      ></recordPopup>
    </el-dialog>
  </div>
</template>

<script>
import recordPopup from "../../components/recordPopup.vue";
export default {
  components: { recordPopup },
  props: [],
  data() {
    return {
      searchEle: {},
      tableData: {
        ipage: {
          records: [],
        },
      },
      totalNum: 0,
      currentPage: 1,
      pageSize: 10,
      recordPopup: {
        show: false,
        res: {},
        type: 1,
      },
    };
  },
  watch: {},
  created() {
    this.getfactorysList();
  },
  methods: {
    //查看代理客户/充值记录
    getRechargeAgentRecord() {
      this.recordPopup.type = 4;
      this.recordPopup.show = true;
      setTimeout(() => {
        this.$refs.record.getRechargeAgentRecord();
      }, 200);
    },
    //打开充值记录弹窗并赋值
    openRecord(row) {
      this.recordPopup.res = row;
      this.recordPopup.type = 1;
      this.recordPopup.show = true;
      setTimeout(() => {
        this.$refs.record.getAllremain();
        this.$refs.record.getRechargeRecordDetailsList();
      }, 200);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getfactorysList();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getfactorysList();
    },
    //获取数据
    getfactorysList() {
      this.http
        .get(
          "/agentAccount/details/getAgentAccountDetailsList",
          this.returnParams()
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = this.tableData.ipage.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选项
    returnParams() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      params = Object.assign({}, this.searchEle, params);
      if (params.time) {
        params.gmtCreateStartDate = params.time[0];
        params.gmtCreateEndDate = params.time[1];
        delete params.time;
      }
      return params;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.container {
  .view {
    .totle {
      .list {
        .bottom {
          height: 46px;
          line-height: 46px;
          font-size: 16px;
          width: 100%;
          background-color: #121276;
        }
        .top {
          .num {
            span {
              color: #ff06bc;
            }
            font-size: 28px;
            margin-right: 10px;
          }
          height: 75px;
          width: 100%;
          line-height: 75px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
        color: white;
        width: 320px;
        border-radius: 8px;
        overflow: hidden;
        text-align: center;
        margin-right: 30px;
      }
      justify-content: flex-start;
    }
    .title {
      span {
        font-size: 14px;
        font-weight: 400;
        padding-left: 20px;
        cursor: pointer;
        color: #00bcd4;
        i {
          padding-right: 5px;
        }
      }
      color: white;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 25px;
    }
    margin-top: 20px;
  }
  margin: auto;
}
</style>
